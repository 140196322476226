<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 头部组件
-->
<template>
  <div class="nav">
    <!-- 黑底nav -->
    <!-- <div class="nav_top_out">
      <div class="nav_top w1200">
        <span class="left font10">{{$t('header.message')}}</span>
        <div class="right language font10">
          <span
            class="language_l"
            :class="[$store.state.language=='zh'?'language_active':'hand']"
            @click="changeLang('zh')"
          >简体</span>
          <span
            class="language_r"
            :class="[$store.state.language=='tc'?'language_active':'hand']"
            @click="changeLang('tc')"
          >繁体</span>
        </div>
        <p class="right font10">{{$t('header.phone')}}</p>
      </div>
    </div> -->
    <!-- 白底nav -->
    <div class="nav_bottom_out">
      <div class="nav_bottom w1200">
        <div class="left">
          <div class="navDiv">
            <router-link to="index" class="nav" @click.native="changePage(0)"><img :src="require('@/assets/img/index/logo.png')" class="left hand"/></router-link>
          </div>
        </div>
        <div class="right">
          <div class="navDiv">
            <router-link to="index" :class="[$store.state.page==0?'nav nav_active':'nav']" @click.native="jumpAnchor('index', 0, 0)">首页</router-link>
          </div>

          <div class="navDiv">
            <router-link to="about" :class="[$store.state.page==1?'nav  nav_active':'nav']" @click.native="jumpAnchor('index', 1, 1)">关于青岛玉龙鼎盛</router-link>
          </div>
          <div class="navDiv">
            <router-link to="issuerList" :class="[$store.state.page==2?'nav  nav_active':'nav']" @click.native="jumpAnchor('index', 2, 2)">金牌发行人</router-link>
          </div>
          <div class="navDiv">
            <router-link to="index" class="mobile" :class="[$store.state.page==3?'nav  nav_active':'nav']" @click.native="jumpAnchor('index', 3, 3)">联系我们</router-link>
            <!-- 二级菜单 -->
            <div class="secondNav">
              <!-- <span v-for="(item,index) in $t('secondHeader.investmentStrategy')"  @click="jumpAnchor('invest', 2, index)">{{item}}</span> -->
              <div class="mobile">
                <div class="mobile_top">
                  <img src="@/assets/img/about/dh.png" alt="">
                  <span>联系我们</span>
                </div>
                <div class="telephone">客服电话： 010-56623100 </div>
                <div class="serviceTime">（服务时间 工作日 09:00-18:00）</div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myHead',
  data() {
    return {
      language: 0, //0简体，1繁体
    }
  },
  props: {},
  methods: {
		//path--要去的页面路由
		//navIndex--二级导航所属的一级导航的索引
		//i--二级导航的索引
		jumpAnchor( path, navIndex, i){

        //要去的页面导航的索引
        this.$emit('changePage', i)

        //如果当前是首页
        if( this.$route.path == '/index' ){
          //首页
          let anchor = document.getElementById('anchor_'+i);
          if( !!anchor){
              let go = anchor.offsetTop;
              document.documentElement.scrollTop = go; 
              document.body.scrollTop = go; 
          }
        }
        // else{
        //   //其他页面（关于我们，投资策略），触发菜单点击事件
        //   document.querySelectorAll('.menu span')[i].click();
        // }
		}
  },
}
</script>

<style lang="scss">
.nav {
  .nav_top_out {
    width: 100%;
    background: #0b121c;
    .nav_top {
      height: 34px;
      line-height: 34px;
      color: #f2f3f5;
      overflow: hidden;
      padding: 0 20px;
      .language {
        margin-left: 30px;
        // width: 80px;
        text-align: center;
        transform:translateY(-50%) scale(0.83, 0.83);
        position: relative;
        top: 50%;
        span {
          float: left;
          // width: 50%;
          width: 40px;
          box-sizing: content-box;
          height: 22px;
          line-height: 21px;
        }
        .language_l {
          // padding: 3px 8px 3px 12px;
          border: 1px solid #fff;
          border-radius: 100px 0 0 100px;
        }
        .language_r {
          // padding: 3px 12px 3px 8px;
          border: 1px solid #fff;
          border-radius: 0 100px 100px 0;
        }
        .language_active {
          background: #fff;
          color: #0b121c;
        }
      }
    }
  }
  .nav_bottom_out {
    .nav_bottom {
      height: 70px;
      line-height: 70px;
      img {
        width: 157px;
        height: 40px;
      }
      .navDiv{
        font-size: 16px;
				color: #232d44;
				margin-left: 26px;
				cursor: pointer;
				display: inline-block;
				position: relative;
				line-height: 23px;
				vertical-align: middle;

				&:first-child{
					padding-bottom: 0;
					margin-left: 0;
				}
        
    }
      .navDiv:hover {
        color: #dab678;

				.secondNav{
					display: block;
				}
      }
      .nav_active {
        // color: #dab678;
        // border-bottom: 2px #dab678 solid;
      }
      .nav_active::after{
        content: '';
        width: 50px;
        height: 3px;
        background: #D8D8D8 linear-gradient(90deg, #C2593A 0%, #E3926C 100%);
        position: absolute;
        left: 50%;
        bottom: -17px;
        margin-left: -25px
      }
    }
  }

  .secondNav{
	  display: none;
	  position: absolute;
	  width: 248px;
    height: 142px;
	  padding-top: 6px;
    padding-bottom: 6px;
	  background: #fff;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.26);
		border-radius: 4px;
		    top: 45px;
				left: -184px;
        z-index: 1;

	  // span{
		//   height: 44px;
		// 	line-height: 44px;
		// 	display: block;
		// 	text-indent: 20px;
	  // 	font-size: 16px;
		// 	font-weight: 400;
		// 	color: #333333;
		// 	// line-height: 16px;

		// 	&:hover{
		// 		background: #DAB678;
		// 	}
	  // }
    .mobile{
      text-align: center;
      .mobile_top{
        margin-top: 23px;
        img{
          width: 20px;
          height: 18px;
        }
        span{
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
          padding-left: 8px;
        }
      }
      .telephone{
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 16px;
        margin-top: 18px;
      }
      .serviceTime{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        margin-top: 12px;
      }
    }
  }
}
</style>