
/****
 * 锚点跳转
 * 
 * @author yangjinlai 20220325
 */


 let myCompOverlay = null;

function addOverlay(overText, map, point) {

    function ComplexCustomOverlay(){
      this._point = {lng:114.169374,lat:22.287062};
    }
    ComplexCustomOverlay.prototype = new BMap.Overlay();
    ComplexCustomOverlay.prototype.initialize = function(map){
      this._map = map;
      let div = this._div = document.createElement("div");

      div.className = 'over-lay-bubble';
      div.style.position = "absolute";
      div.style.zIndex = BMap.Overlay.getZIndex(this._point.lat);
      div.style.backgroundColor = "#DAB678";
      div.style.borderRadius = "4px";
      div.style.color = "#333333";
      div.style.fontWeight = "bold";
      // div.style.height = "20px";
      div.style.padding = "7px 8px";
      div.style.lineHeight = "12px";
      div.style.whiteSpace = "nowrap";
      div.style.MozUserSelect = "none";
      div.style.fontSize = "12px"
      let span = this._span = document.createElement("span");
      div.appendChild(span);
      span.appendChild(document.createTextNode(overText));      

      this._map.getPanes().labelPane.appendChild(div);
      return div;
    }
    ComplexCustomOverlay.prototype.draw = function(){
      let map = this._map;
      let pixel = map.pointToOverlayPixel(this._point);
      this._div.style.left = pixel.x - 32 + "px";
      this._div.style.top  = pixel.y - 55 + "px";
    }
    myCompOverlay = new ComplexCustomOverlay(point, overText);
    
    map.addOverlay(myCompOverlay)
 }

  export default function( ) {
    myCompOverlay && this.$store.state.map.removeOverlay(myCompOverlay) 
    addOverlay(this.$t('normal.mapMarkerText'), this.$store.state.map, this.$store.state.point)
  }