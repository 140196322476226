<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 尾部组件
-->
<template>
  <div class="footer_out">
    <div class="footer w1200">
      <div class="introTitle2">
          <p>CONTACT US</p>
          <span>联系我们</span>
          <i></i>
      </div>
      <div class="bottom-contant">
        <div class="kefu-wrap left">
          <a href="tel:010-56623100">
            <img :src="require('@/assets/img/index/kefu_phone.png')" class="left kefu-phone"/>
          </a>
          <div class="kefu left">
            <p class="kefu-tit">客服电话</p>
            <div>
              <a href="tel:010-56623100"><p class="left">010-56623100</p></a>
              <span class="left">服务时间 工作日 09:00-18:00</span>
            </div>
          </div>
        </div>
        <div class="logo-wrap right">
          <img :src="require('@/assets/img/index/logo.png')" class="logo"/>
        </div>
      </div>
      <div class="bottom">
        CopyRight  版权所有  青岛玉龙鼎盛投资管理有限公司 All Rights Reserved&nbsp;
        <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2021029605号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myFoot',
  methods: {
    changePage(i) {
      this.$emit('changePage', i)
    },
  },
}
</script>

<style lang='scss'>
.footer_out {
  width: 100%;
  background: #FFFFFF;
  text-align: center;
  .footer {
    // height: 423px;
    overflow: hidden;
    font-size: 13px;
    color: #ffffff;
    .introTitle2 {
        text-align: center;
        padding-top: 70px;
        p {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 26px;
            margin: 0 0 12px;
        }
        span {
            display: block;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            margin-bottom: 18px;
        }
        i {
            width: 42px;
            height: 4px;
            background: #D8D8D8 linear-gradient(90deg, #C2593A 0%, #E3926C 100%);;
            display: inline-block;
        }
    }
    .bottom-contant{
      overflow: hidden;
      box-sizing: border-box;
      margin-top:60px;
      padding-bottom: 50px;
      border-bottom: 1px dashed #CCCCCC;
      .kefu-wrap{
        .kefu-phone{
          display: block;
          width: 70px;
          height: 70px;
          margin-right: 20px;
          cursor: pointer;
        }
        .kefu{
          line-height: 20px;
          text-align: left;
          padding:9px 0;
          .kefu-tit{
            height: 20px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            margin-bottom:12px;
          }
          div{
            overflow: hidden;
            p{
              height: 20px;
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 20px;
              background: -webkit-linear-gradient(180deg, #C2593A 0%, #E3926C 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              margin-right: 10px;
              cursor: pointer;
            }
            span{
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
      .logo-wrap{
        height: 70px;
        position: relative;
        .logo{
          position: absolute;
          right: 0;
          top:50%;
          transform: translateY(-50%);
          width: 157px;
        }
      }
      
    }
    .bottom{
      height: 76px;
      line-height: 76px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      a{
        color: #999999;
        cursor: pointer;
      }
      a:hover{
        color: #666666;
      }
    }
  }
}
</style>