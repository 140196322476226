/*
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 
 */
import Vue from 'vue'
import Router from 'vue-router'

const index = r => require.ensure([], () => r(require('@/pages/home/index.vue')), 'chunkname1');

//发行人详情
const about = r => require.ensure([], () => r(require('@/pages/about/index.vue')), 'chunkname-about');


//发行人详情
const publisherDetail = r => require.ensure([], () => r(require('@/pages/publisher/detail.vue')), 'chunkname-publisherDetail');




Vue.use(Router)
const router = new Router({
  mode: '',
  routes: [
    {path: '/', redirect: '/index'},
    {
      path: '/index',
      name: 'index',
      component: index
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/pages/about/index.vue')
    },
    //发行人详情
    {
      path: '/publisherDetail',
      name: 'publisherDetail',
      component: publisherDetail
    },
    //咨询详情
    {
      path: '/detaillist',
      name: 'detaillist',
      component: () => import('@/pages/publisher/detaillist.vue')
    },
    //金牌发行人
    {
      path: '/issuerList',
      name: 'issuerList',
      component: () => import('@/pages/publisher/issuerList.vue')
    },
  ]
})

// 全局路由守卫
router.afterEach((to, from, next) => {
  // console.log( to )

  document.documentElement.scrollTop = 0; 
  document.body.scrollTop = 0; 
  
});

export default router

