/*
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 
 */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "babel-polyfill"
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import xss from 'xss'

Vue.config.productionTip = false

import './assets/css/base.scss';
import './assets/css/common.scss';
import 'element-ui/lib/theme-chalk/index.css'


// 绑定日期转化插件
import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs

//绑定公共anchor方法
import anchorjs from './utils/anchor'
Vue.prototype.$anchorjs = anchorjs

import mapjs from './utils/map'
Vue.prototype.$mapjs = mapjs


import moment from 'moment'
Vue.prototype.$moment = moment;

// 全局过滤器
import * as filters from './mixins/filters'
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})



import i18n from './lang'
import changeLang from './utils/changeLang'
Vue.prototype.$lang = changeLang
Vue.config.productionTip = false
Vue.filter('sLang', function(value) {
  return changeLang(value, store.state.language)
})

// 图片懒加载
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: require('./assets/img/img_lazy_bg.png'),   //请求失败后显示的图片
	loading: require('./assets/img/img_lazy_bg.png'),  // 加载中图片
	attempt: 1     // 加载图片数量
});

import {
  Carousel,
  CarouselItem,
  Pagination,
  Select,
  Option,
  Step,Steps
 
} from 'element-ui';

Vue.use(Carousel).use(CarouselItem).use(Pagination).use(Select).use(Option).use(Step).use(Steps)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'Ir969VRnTy9Ca2pfbDsv4UcCF8L9cMGL'
})



/* eslint-disable no-new */

let myVue = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  xss,
  components: {
    App
  },
  template: '<App/>'
})

export default myVue
