/*
 * @Author: diaowangtao
 * @Date: 2020-08-25 14:24:14
 * @Description: 
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import Cookies from 'js-cookie'
//  import zhCN from 'vant/lib/locale/lang/zh-CN'
//  import zhHK from 'vant/lib/locale/lang/zh-HK'
import zhLocale from './zh'
import tcLocale from './tc'
import store from '@/store'

Vue.use(VueI18n)

const messages = {
  zh: {
    ...zhLocale,
    //...zhCN
  },
  tc: {
    ...tcLocale,
    //...zhHK
  }
}

const i18n = new VueI18n({
  locale: store.state.language || 'zh',
  messages
})

export default i18n
