/*
 * @Author: diaowangtao
 * @Date: 2020-08-25 14:24:14
 * @Description: 
 */
export default {
  header: {
    home: '首頁',
    company: '關於我們',
    investmentStrategy: '投資策略',
    online:'線上開戶',
    joinUs: '加入我們',
    contactUs: '聯系我們',
    copyRight: '隱私聲明',
    disclaimer: '免責聲明',
    postDetail: '職位詳情',
    articleDetail: '文章詳情',
    lastInvestment: '2019恒天投資展望',
    currentInvestment: '2020投資展望',
    relevantArticle: '相關文章',
    message:'您好，歡迎訪問恒天國際資本管理有限公司！',
    phone:'客服電話：+852 3702 7888'
  },
  //二级导航内容
  secondHeader:{
    home: ['公司介紹', '投資策略', '新聞中心'],
    company: ['公司介紹', '企業文化', '大事記', '新聞中心'],
    investmentStrategy: ['專題文章', '投資展望']
  },
  footer: {
    website: '恒天財富官網',
    copyRight_l: 'CopyRight@2020~現在 版權所有 ',
    copyRight_c:'恒天國際資本管理有限公司 All Rights Reserved',
    copyRight_r:'津ICP備16003004號'
  },
  normal: {
    nameTip: '請填寫姓名',
    phone: '您的電話*',
    phoneTip: '請填寫電話',
    email: '您的郵箱*',
    emailTip: '請填寫郵箱',
    emailError: '請填寫正確的郵箱',
    company: '您的公司名稱',
    theme: '聯系主題*',
    themeTip: '請填寫聯系主題',
    contentTip: '請填寫更多描述',
    recommends: '推薦文章',
    viewMore: '瀏覽更多相關文章',
    areaCode: '請選擇國家或地區',
    please:'請輸入',
    mapMarkerText:'恆天國際'
  },
  online:{
    code:'掃描上方二維碼',
    clickThe:'點擊這裏',
    forThe:'進行開戶',
    one:'輸入並驗證手機號碼',
    two:'拍攝並上傳身份證',
    three:'填寫個​​人資料及風險取向問卷',
    four:'自拍並進行身份驗證',
    five:'專業投資者認證',
    six:'完成',
  }
}
