<!--
 * @Author: diaowangtao
 * @Date: 2020-08-26 09:14:27
 * @Description: 网络异常弹窗
-->
<template>
  <div class="error">
    <img :src="require('@/assets/img/error.png')" alt />
  </div>
</template>

<script>
export default {
  name: 'myError',
}
</script>

<style lang='scss'>
.error {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  img {
    display: block;
    width: 240px;
    height: 240px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
  }
}
</style>