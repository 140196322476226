/**
 * 格式化百分号颜色  大于0红色， 小于0绿色
 * @param value 格式化的值
 * @returns {{color: string}}
 * @constructor
 */
import Vue from 'vue'
const FILTER_FORMATE_INCOME_COLOR = (value) => {
        if (value && value.toString().indexOf('-') > -1) {
            return {
                color: '#6DC135'
            }
        } else if (value === 0) {
            return {
                color: '#E1312E'
            }
        } else if (value) {
            return {
                color: '#E1312E'
            }
        } else if (!value) {
            return {
                color: '#999999'
            }
        }
    }
    // 首页正负颜色判断
const FILTER_FORMATE_COLOR_RATE = (value) => {
    if (value && value.toString().indexOf('-') > -1) {
        return {
            color: '#6DC135'
        }
    } else if (value) {
        return {
            color: '#E1312E'
        }
    }
}

// 首页正负颜色判断
const FILTER_FORMATE_COLOR_INDEX = (value, col) => {
    if (value && value.toString().indexOf('-') > -1) {
        return {
            color: '#6DC135'
        }
    } else if (value) {
        return {
            color: col
        }
    }
}

const FILTER_FORMATE_NUM = (value) => {
        if (value && value.toString().indexOf('-') > -1) {
            return value
        } else if (value) {
            return "+" + value
        } else if (value === 0) {
            return value
        } else if (!value) {
            return '--'
        } else {
            return value
        }
    }
    // 判断空
const FILTER_FORMATE_NULL = (value) => {
        if (!value) {
            return '--'
        } else if (value && value.toString().indexOf('null') > -1) {
            return '--'
        } else {
            return value
        }
    }
    //去掉百分号，返回空显示--
const FILTER_FORMATE_PERCENT0 = (value) => {
        if (!value && value !== 0) {
            return '--'
        } else if (value && value.toString().indexOf('%') > -1) {
            return value.replace('%', '')
        } else {
            return value
        }
    }
    // 限额0万
const FILTER_FORMATE_LIMIT = (value) => {
    if (!value) {
        return '0万'
    } else if (value && value.toString().indexOf('null') > -1) {
        return '0万'
    } else {
        return value
    }
}

/**
 * 过滤%
 * @param value
 * @returns {*}
 * @constructor
 */
const FILTER_PERCENT = (value) => {
        if (value && value.toString().indexOf('%') > -1) {
            return value.replace('%', '')
        } else if (value) {
            return value
        }
    }
    // 用于样式不同的百分号判断，如果值带%，只返回%，如果没有%，则返回空
const FILTER_PERCENT_SHOW = (value) => {
        if (value && value.toString().indexOf('%') > -1) {
            return '%'
        } else if (value) {
            return ''
        }
    }
    // 用于时间戳转换时间
const FILTER_FORMATE_TIME = (time) => {
    if (!time) { // 当时间是null或者无效格式时我们返回空
        return ' '
    } else {
        const date = new Date(time) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        const dateNumFun = (num) => num < 10 ? `0${num}` : num // 使用箭头函数和三目运算以及es6字符串的简单操作。因为只有一个操作不需要{} ，目的就是数字小于10，例如9那么就加上一个0，变成09，否则就返回本身。
            // 这是es6的解构赋值。
        const [Y, M, D, h, m, s] = [
            date.getFullYear(),
            dateNumFun(date.getMonth() + 1),
            dateNumFun(date.getDate()),
            dateNumFun(date.getHours()),
            dateNumFun(date.getMinutes()),
            dateNumFun(date.getSeconds())
        ]
        return `${Y}-${M}-${D}` // 一定要注意是反引号，否则无效。
            // return `${Y}-${M}-${D} ${h}:${m}:${s}` 
    }
}

// 业绩比较基准判断
const FILTER_DIFFERENT = (item) => {
    if (item.expectedReturnsMin && item.expectedReturnsMax) {
        if (item.expectedReturnsMin == item.expectedReturnsMax) {
            if (item.expectedReturnsMin == 0 && item.expectedReturnsMax == 0) {
                return '--'
            } else {
                return item.expectedReturnsMin
            }
        } else {
            return item.expectedReturnsMin + '-' + item.expectedReturnsMax
        }
    }
}

// 起投金额，后端返回那个类型有值，就使用那个类型，如果都没有值得话，显示认证可见
const FILTER_STARTINVEST = (item) => {
        if (item.perInvestStart || item.perInvestStart == 0) { //个人起投金额
            return item.perInvestStart + '万'
        } else if (item.orgInvestStart || item.orgInvestStart == 0) { //机构起投金额
            return item.orgInvestStart + '万'
        } else {
            return '认证可见'
        }
    }
    // 产品详情页面投资期限判断
const FILTER_TIME_LIMIT = (item) => {
        if (item.investTermMin && item.investTermMax) {
            if (item.investTermMin == item.investTermMax && item.investTermUnitName) {
                return item.investTermMax + item.investTermUnitName
            } else if (item.investTermUnitName) {
                return item.investTermMin + '-' + item.investTermMax + item.investTermUnitName
            } else {
                return item.investTermMax
            }
        } else if (item.investTermMin || item.investTermMax) {
            if (item.investTermMax) {
                return item.investTermMax + item.investTermUnitName || '';
            } else {
                return item.investTermMin + item.investTermUnitName || '';
            }
        } else {
            return '--'
        }
    }
    // 2021-03-17 15:15:55 转为2021年3月17日
const FILTER_DATE_STRING = (date) => {
        if (!date) { // 当时间是null或者无效格式时我们返回空
            return '';
        } else {
            var dateString = date.split(' ')[0].split('-');
            return `${dateString[0]}年${dateString[1]*1}月${dateString[2]*1}日`;
        }
    }
    // 数字转为货币形式字符
const FILTER_NUMBER_MONEY = (num) => {
    let numberStr = num.toString()
    let str = numberStr.split('').reverse()
    for (let i = 0; i < str.length; i++) {
        if ((i + 1) % 4 === 0) {
            str.splice(i, 0, ',')
        }
    }
    str.reverse()
    let handleResult = ''
    for (let j = 0; j < str.length; j++) {
        handleResult += str[j]
    }
    if (handleResult.indexOf('.') < 0) {
        handleResult += '.00'
    }
    return handleResult
};
// 格式化时间
const FILTER_FORMAT_TIME = (time, formatText) => {
    if (!time) {
        return '--';
    } else {
        return Vue.prototype.$moment(time).format(formatText || 'YYYY-MM-DD HH:mm:ss');
    }
};
export {
    FILTER_FORMATE_INCOME_COLOR,
    FILTER_FORMATE_NUM,
    FILTER_FORMATE_NULL,
    FILTER_FORMATE_LIMIT,
    FILTER_FORMATE_PERCENT0,
    FILTER_PERCENT_SHOW,
    FILTER_FORMATE_COLOR_INDEX,
    FILTER_FORMATE_COLOR_RATE,
    FILTER_FORMATE_TIME,
    FILTER_DIFFERENT,
    FILTER_STARTINVEST,
    FILTER_TIME_LIMIT,
    FILTER_DATE_STRING,
    FILTER_NUMBER_MONEY,
    FILTER_FORMAT_TIME
}