/*
 * @Author: diaowangtao
 * @Date: 2020-08-25 14:24:14
 * @Description: 
 */
export default {
    header: {
        companyIntro: '公司介绍',
        seniorManage: '高管介绍',
        goldIssuer: '金牌发行人',
        contactUs: '联系我们',
        home: '首页',
        company: '关于我们',
        investmentStrategy: '投资策略',
        online: '线上开户',
        joinUs: '加入我们',
        copyRight: '隐私声明',
        disclaimer: '免责声明',
        postDetail: '职位详情',
        articleDetail: '文章详情',
        lastInvestment: '2019恒天投资展望',
        currentInvestment: '2020投资展望',
        relevantArticle: '相关文章',
        message: '您好，欢迎访问青岛玉龙鼎盛投资管理有限公司！',
        phone: '客服电话：+852 3702 7888',

    },
    //二级导航内容
    secondHeader: {
        home: ['公司介绍', '投资策略', '新闻中心'],
        company: ['公司介绍', '企业文化', '大事记', '新闻中心'],
        investmentStrategy: ['专题文章', '投资展望']
    },
    footer: {
        website: '恒天财富官网',
        copyRight_l: 'CopyRight@2020~现在 版权所有 ',
        copyRight_c: '青岛玉龙鼎盛投资管理有限公司 All Rights Reserved',
        copyRight_r: '津ICP备16003004号'
    },
    normal: {
        nameTip: '请填写姓名',
        phone: '您的电话*',
        phoneTip: '请填写电话',
        email: '您的邮箱*',
        emailError: '请填写正确的邮箱',
        emailTip: '请填写邮箱',
        company: '您的公司名称',
        theme: '联系主题*',
        themeTip: '请填写联系主题',
        contentTip: '请填写更多描述',
        recommends: '推荐文章',
        viewMore: '浏览更多相关文章',
        areaCode: '请选择国家或地区',
        please: '请输入',
        mapMarkerText: '恒天国际'
    },
    online: {
        code: '扫描上方二维码',
        clickThe: '点击这里',
        forThe: '进行开户',
        one: '输入并验证手机号码',
        two: '拍摄并上传身份证',
        three: '填写个​​人资料及风险取向问卷',
        four: '自拍并进行身份验证',
        five: '专业投资者认证',
        six: '完成',
    }
}